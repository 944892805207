.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;

    @media (max-height: 600px) {
      height: 550px;
    }
    @media (min-height: 600px) and (max-height: 699px) {
      height: 600px;
    }
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background-color: #050624;
    // z-index: 10000;
    display: flex;
    flex-direction: row;

    align-items: center;
    color: white;
    padding-left: 15px;
    padding-right: 5px;
    font-size: 22px;
    font-weight: 700;

    .logo {
      height: 37px;
      width: 37px;
      margin-right: 5px;
      margin-top: 10px;
    }

    // .clinicAddress {
    //   margin-top: 10px;
    //   margin-left: 20px;
    //   margin-right: 0px;
    //   padding: 8px;
    //   border: 1px solid #2a2d56;
    //   font-size: 12px;
    //   border-radius: 6px;
    //   text-transform: capitalize;
    //   // max-width: 140px;
    //   max-height: 50px;
    // }

    @media (min-width: 600px) {
      position: absolute;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }
  .scheduleDetails::-webkit-scrollbar {
    display: none;
    width: 0px;
    height: 0px;
  }
  .scheduleDetails {
    padding-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
    .slotContainer::-webkit-scrollbar {
      display: none;
      width: 0px;
      height: 0px;
    }
    .slotContainer {
      height: 486px;
      width: 100%;
      // margin-left: 1.1rem;
      padding-bottom: 5rem;
      overflow-y: auto;
      @media (min-height: 450px) and (max-height: 599px) {
        height: 280px;
        overflow-y: auto;
      }
      @media (min-height: 601px) and (max-height: 699px) {
        height: 380px;
        overflow-y: auto;
      }
      // @media (min-height: 550px) and (max-width: 800px) {
      //   margin-left: 0.7rem;
      // }
      .slotButton {
        width: 99%;
        @media (min-width: 600px) {
          height: 45px;
        }
        height: 54px;

        // margin-left: 12px;
        // margin-right: 12px;

        // height: 56px;
        // max-width: 330px;
        // @media (min-width: 380px) and (max-width: 599px) {
        //   min-width: 330px;
        //   max-width: none;
        // }
        // @media (min-width: 600px) {
        //   font-size: 12px;
        //   height: 30px;
        // }
        // @media (max-width: 480px) {
        //   font-size: 12px;
        //   height: 30px;
        // }
      }
      .noSlotDiv {
        margin-top: 124px;
        @media (max-height: 700px) {
          margin-top: 70px;
        }
        text-align: center;
      }
      .noSlots {
        color: #e5e7eb;
        font-family: 'Wix Madefor Display';
        font-size: 18px;
        @media (min-width: 600px) {
          font-size: 18px;
        }
        @media (max-width: 300px) {
          font-size: 10px;
        }
        font-style: normal;
        font-weight: 700;
        margin-top: 14px;
        line-height: 140%; /* 25.2px */
      }
      .dummySpan {
        margin-top: 0.6rem;
        color: #e5e7eb;
        font-family: 'Wix Madefor Display';
        font-size: 16px;
        @media (min-width: 600px) {
          font-size: 16px;
        }
        @media (max-width: 300px) {
          font-size: 10px;
        }
        font-style: normal;
        font-weight: 100;
        line-height: 140%; /* 19.6px */
      }
    }
    .errorContainer {
      background: #f24343;
      width: 100%;
      height: 48px;
      @media (min-width: 600px) {
        height: 40px;
      }
      // margin-top: 20px;
      border-radius: 4px;
      padding-left: 16px;
      color: #fff;
      display: flex;
      align-items: center;
    }
    // datePicker styles start
    .toDayDate {
      color: var(--dark-light-text, #e5e7eb);
      // text-align: center;
      font-size: 18px;
      @media (min-width: 600px) {
        font-size: 14px;
      }
      font-weight: 700;
      line-height: 25.2px;
      margin-left: 0.3rem;
      @media (min-width: 600px) {
        // margin-left: 1.1rem;
        font-size: 16px;
      }
      margin-top: 18px;
      margin-bottom: 20px;
      overflow-y: auto;
    }
  }
  .btnWrapper {
    button {
      width: 100%;
    }
  }
}

.modalPopUp {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   width: 250;
  //   height: 150;
  //   background-color: #050624;
  //   color: white;
  border-right: 8px;
}
