.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 15px 5px 15px;

    @media (max-height: 600px) {
      height: 550px;
      height: 550px;
    }
  }
  padding: 5px 15px 5px 15px;
  .header {
    display: flex;
    justify-content: flex-end;
    font-size: 22px;
    font-weight: 700;
    color: white;

    .logo {
      height: 37px;
      width: 37px;
      margin-right: 5px;
      margin-top: 10px;
    }
  }
  .welcomeContainer {
    margin-top: 50px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    color: #e5e7eb;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
    .headerText {
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
    }
    .messageText {
      font-size: 16px;
      font-weight: 400;
      margin-top: 10px;
    }
  }
}
